import $ from 'jquery';


$(document).on('turbo:load', function () {
  $('.download-button').on('click', function () {
    var button = $(this);
    button.addClass('pressed');
    setTimeout(function () {
      button.removeClass('pressed');
    }, 3000);
  });
});
