import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='wishlist'
export default class extends Controller {
  static targets = ["tab", "pane", "downloadSpecWindow", "closeSpecWindow", "loadSpecsBtn"];

  connect() {
    // Ensure the active tab and pane are correctly displayed on load
    this._ensureActiveState();
  }

  toggle(event) {
    event.target.classList.toggle('active');
  }

  toggleShow() {
    this.element.classList.toggle('pressed');
  }

  switchTab(event) {
    // Deactivate all tabs
    this.tabTargets.forEach(tab => tab.classList.remove("active"));

    // Activate the clicked tab
    const clickedTab = event.currentTarget;
    clickedTab.classList.add("active");

    // Hide all panes
    this.paneTargets.forEach(pane => pane.classList.remove("active", "hidden"));

    // Show the target pane
    const target = clickedTab.dataset.target;
    const targetPane = this.paneTargets.find(pane => pane.classList.contains(target));

    if (targetPane) {
      targetPane.classList.add("active");
    }

    if (target === "plumbings-product") {
      this.loadSpecsBtnTarget.style.display = "";
      this.loadSpecsBtnTarget.setAttribute("data-wishlist-spec-param", "plumbings-product");
    } else if (target === "tiles-product") {
      this.loadSpecsBtnTarget.style.display = "";
      this.loadSpecsBtnTarget.setAttribute("data-wishlist-spec-param", "tiles-product");
    } else {
      this.loadSpecsBtnTarget.style.display = "none";
    }
  }

  openSpecWindow(event) {
    this.downloadSpecWindowTargets.forEach(downloadSpec => {
      if (downloadSpec.classList.contains(event.params.spec)) {
        downloadSpec.style.visibility = "visible";
      } else {
        downloadSpec.style.visibility = "hidden";
      }
    });

    document.body.style.overflow = "hidden";
  }

  closeSpecWindow(event) {
    this.downloadSpecWindowTargets.forEach(downloadSpec => {
      downloadSpec.style.visibility = "hidden";
    });

    document.body.style.overflow = "";
  }

  _ensureActiveState() {
    // Ensure one tab and its corresponding pane are active
    const activeTab = this.tabTargets.find(tab => tab.classList.contains("active"));

    if (activeTab) {
      const target = activeTab.dataset.target;
      const targetPane = this.paneTargets.find(pane => pane.classList.contains(target));

      if (targetPane) {
        targetPane.classList.add("active");
      }
    }
  }
}
