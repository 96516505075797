import $ from 'jquery';


$(document).on('turbo:load', function () {
  let $overlayElement = $('.compare-box-overlay-onpage');
  let $closeButton = $('.close-compare-box-onpage');
  let $openButtons = $('.compare-box-open-onpage');

  if ($openButtons.length) {
    function openPopup() {
      $overlayElement.css('visibility', 'visible');
      $('body').css('overflow', 'hidden');
    }

    function closePopup() {
      $overlayElement.css('visibility', 'hidden');
      $('body').css('overflow', '');
    }

    $closeButton.on('click', closePopup);

    $overlayElement.on('click', function (event) {
      if ($(event.target).is($overlayElement)) {
        closePopup();
      }
    });

    $openButtons.each(function () {
      $(this).on('click', openPopup);
    });
  }
});



$(document).on('turbo:load', function () {
  function equalizeFeaturesHeight() {
    const cards = $(".features-box");

    $(".feature").css("height", "");

    const featureCount = $(cards[0]).find(".feature").length;

    for (let i = 0; i < featureCount; i++) {
      let maxHeight = 0;

      const features = cards.map(function () {
        return $(this).find(".feature").eq(i);
      });

      features.each(function () {
        const height = $(this).outerHeight();
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      features.each(function () {
        $(this).height(maxHeight);
      });
    }
  }

  equalizeFeaturesHeight();

  window.addEventListener('resize', function () {
    $(".feature").css("height", "");
    equalizeFeaturesHeight();
  });

  let lockedSlides = [];

  function handleLockClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const lockButton = $(event.currentTarget);
    const item = lockButton.closest('.compare-item');
    const swiperSlide = item.closest('.swiper-slide');
    const fixedContainer = $('.wrapper');
    const lockedItems = $('.locked-item');

    if (item.hasClass('locked-item')) {
      lockButton.removeClass('active');
      const originalSlide = lockedSlides.find(slide => slide.clone === item[0]);

      if (originalSlide) {
        $(originalSlide.slide).css('display', 'block');
        const originalLockButton = $(originalSlide.slide).find('.lock-btn');
        originalLockButton.removeClass('active');
        lockedSlides = lockedSlides.filter(slide => slide.clone !== item[0]);
      }
      item.remove();

      equalizeFeaturesHeight();
    } else {
      if (lockedSlides.length >= 2) {
        return;
      }

      lockButton.addClass('active');
      const clonedItem = item.clone().addClass('locked-item');
      if (lockedItems.length === 0) {
        fixedContainer.prepend(clonedItem);
      } else {
        lockedItems.last().after(clonedItem);
      }
      $(swiperSlide).css('display', 'none');
      lockedSlides.push({ slide: swiperSlide, clone: clonedItem[0] });

      const clonedLockButton = clonedItem.find('.lock-btn');
      clonedLockButton.on('click', handleLockClick);

      equalizeFeaturesHeight();
    }

    const allLocks = $('.lock-btn');
    if (lockedSlides.length === 2) {
      allLocks.each(function () {
        if (!$(this).closest('.locked-item').length) {
          $(this).css('display', 'none');
        }
      });
    } else {
      allLocks.css('display', '');
    }
  }

  $('.lock-btn').on('click', handleLockClick);
});
