import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

// Connects to data-controller="plumbings-collection-index-filters"
export default class extends Controller {
  static targets = [
    "filtersOverlay",
    "filtersWrapper",
    "closeBtn",
    "openFilterBtns",
    "filterItem",
    "selectedFiltersInFilter",
    "selectedFiltersOnPage"
  ];

  connect() {
    this.filtersOverlayTarget.style.visibility = "hidden";
    document.body.style.overflow = "";

    this.filtersOverlayTarget.addEventListener("click", this.closeOnClickOutside.bind(this));
  }

  openFilterNavbar() {
    this.filtersOverlayTarget.style.visibility = "visible";
    document.body.style.overflow = "hidden";
  }

  closeFilterNavbar() {
    this.filtersOverlayTarget.style.visibility = "hidden";
    document.body.style.overflow = "";
  }

  closeOnClickOutside(event) {
    if (
      event.target === this.filtersOverlayTarget &&
      !this.filtersWrapperTarget.contains(event.target)
    ) {
      this.closeFilterNavbar();
    }
  }

  clearFilters() {
    this.filterItemTargets.forEach((item) => {
      item.classList.remove("active");
    });

    this.selectedFiltersInFilterTarget.innerHTML = "";
    this.selectedFiltersOnPageTarget.innerHTML = "";
    history.pushState({}, "", window.location.pathname);
  }

  toggleFilter(event) {
    const filterElement = event.currentTarget;
    const filterSlug = filterElement.dataset.slug;
    const filterName = filterElement.dataset.name;

    if (filterElement.classList.contains("active")) {
      this._removeFilterFromUrl(filterSlug);
      this._removeFilterFromSelectedFilters(filterSlug, filterName);
      filterElement.classList.remove("active");
    } else {
      this._addFilterToUrl(filterSlug);
      this._addFilterToSelectedFilters(filterSlug, filterName);
      filterElement.classList.add("active");
    }
  }

  removeSelectedFilterInFilter(event) {
    const filterSlug = event.currentTarget.dataset.slug;

    this._baseRemoveSelectedFilter(filterSlug);
    this._removeSelectedOnPageFilter(filterSlug);
  }

  removeSelectedFilterOnPage(event) {
    const filterSlug = event.currentTarget.dataset.slug;

    this._baseRemoveSelectedFilter(filterSlug);
    this._removeSelectedInFilterFilter(filterSlug)
  }


  _addFilterToSelectedFilters(filterSlug, filterName) {
    const selectedItemInFilter = document.createElement("div");
    selectedItemInFilter.classList.add("selected-item");
    selectedItemInFilter.innerHTML = `
      <div class="small-text regular">${filterName}</div>
      <div class="remove" data-action="click->plumbings-collection-index-filters#removeSelectedFilterInFilter" data-slug=${filterSlug}></div>
    `;

    this.selectedFiltersInFilterTarget.appendChild(selectedItemInFilter);

    const selectedItemOnPage = document.createElement("div");
    selectedItemOnPage.classList.add("selected-item");
    selectedItemOnPage.innerHTML = `
      <div class="small-text regular">${filterName}</div>
      <div class="remove" data-action="click->plumbings-collection-index-filters#removeSelectedFilterOnPage" data-slug=${filterSlug}></div>
    `;

    this.selectedFiltersOnPageTarget.appendChild(selectedItemOnPage);
  }

  _removeFilterFromSelectedFilters(filterSlug) {
    this._removeSelectedInFilterFilter(filterSlug);
    this._removeSelectedOnPageFilter(filterSlug);
  }

  _baseRemoveSelectedFilter(filterSlug) {
    this._removeFilterFromUrl(filterSlug);

    const filterItem = this.filterItemTargets.find(
      (item) => item.dataset.slug === filterSlug
    );

    filterItem?.classList?.remove("active");
    event.currentTarget.parentElement.remove();
  }


  _removeSelectedInFilterFilter(filterSlug) {
    const inFiltersFilterItem = this.selectedFiltersInFilterTarget.querySelector(`[data-slug="${filterSlug}"]`);
    inFiltersFilterItem?.parentElement?.remove();
  }

  _removeSelectedOnPageFilter(filterSlug) {
    const onPageFilterItem = this.selectedFiltersOnPageTarget.querySelector(`[data-slug="${filterSlug}"]`);
    onPageFilterItem?.parentElement?.remove();
  }


  _addFilterToUrl(filterSlug) {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    let filters = params.get("filters") ? params.get("filters").split(",") : [];

    if (!filters.includes(filterSlug)) { filters.push(filterSlug); }

    params.set("filters", filters.join(","));

    params.delete("page");

    this._updateUrl(params);
    this._filterIndexPage(params);
  }

  _removeFilterFromUrl(filterSlug) {
    const url = new URL(window.location.href);
    const params = url.searchParams;

    let filters = params.get("filters") ? params.get("filters").split(",") : [];
    filters = filters.filter((filter) => filter !== filterSlug);

    if (filters.length > 0) {
      params.set("filters", filters.join(","));
    } else {
      params.delete("filters");
    }

    params.delete("page");

    this._updateUrl(params);
    this._filterIndexPage(params);
  }

  _updateUrl(params) {
    if (params.toString() === "") {
      history.pushState({}, "", window.location.pathname);
      return;
    }

    const url = `${window.location.pathname}?${params.toString()}`;
    history.pushState({}, "", url);
    this.dispatch("urlUpdated", { detail: { url } });
  }

  _filterIndexPage(params) {
    Rails.ajax({
      type: 'POST',
      url: `/plumbings/filter_index`,
      data: params,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => { Turbo.renderStreamMessage(data); },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
