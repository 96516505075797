import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collection-switcher"
export default class extends Controller {
  static targets = [
    "textureSlider",
    "interierSlider",
    "switcher"
  ]

  connect() {
    this.initializeSwitchers();
  }

  initializeSwitchers() {
    this.switcherTargets.forEach((switcher) => {
      switcher.addEventListener("click", this.toggleSliders.bind(this));
    });
  }

  toggleSliders() {
    this.textureSliderTarget.classList.toggle("hidden");
    this.interierSliderTarget.classList.toggle("hidden");

    this.updateSwiper(this.textureSliderTarget);
    this.updateSwiper(this.interierSliderTarget);
  }

  updateSwiper(slider) {
    if (slider?.swiper) {
      slider.swiper.update();
    }
  }
}
