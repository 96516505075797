import { Controller } from '@hotwired/stimulus';

// Connects to data-controller='multiple-picture-uploader'
export default class extends Controller {
  static targets = ['fileInputPartial', 'addButton', 'formContainer'];

  handleFiles(event) {
    const files = Array.from(event.target.files);
    files.forEach((file) => {
      this.createNewForm(file);
    });

    // Clear the file input so the same files can be uploaded again if needed
    this.fileInputPartialTarget.remove();
    this.addButtonTarget.style.display = 'block';
  }

  createNewForm(file) {
    setTimeout(() => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageUrl = event.target.result;

        this.addButtonTarget.click();

        // Find the last form added by the nested form logic
        const allNewForms = this.formContainerTarget.querySelectorAll('.nested-form-wrapper');
        const newForm = allNewForms[allNewForms.length - 1];

        // Update the file input and image preview in the new form
        const fileInput = newForm.querySelector('input[type="file"]');
        const imagePreview = newForm.querySelector('.prew-image');

        if (fileInput && imagePreview) {
          fileInput.files = this._createFileList(file);
          imagePreview.src = imageUrl;
          imagePreview.style.display = 'block';
        } else {
          console.error('File input or image preview not found in the new form.');
        }
      };

      reader.readAsDataURL(file);
    }, 1000);
  }

  // Helper to create a FileList object for setting the input value
  _createFileList(file) {
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    return dataTransfer.files;
  }
}
