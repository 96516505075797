import { Controller } from "@hotwired/stimulus";

// Connects to data-controller='admin-tiles-product-actual-format-setter'
export default class extends Controller {
  static targets = ["actualFormat", "collectionFormat"];

  changeFormat(event) {
    const collectionFormatText = this.collectionFormatTarget.options[this.collectionFormatTarget.selectedIndex].text;

    this.actualFormatTarget.value = collectionFormatText;
  }
}
