import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-select"
export default class extends Controller {
  static targets = ["header", "hiddenSelect", "list", "item", "arrow", "text"]

  connect() {
    this._setCurrentValue()
    this._updateHeaderText();
    this._hideList();
  }

  toggleList() {
    this.listTarget.classList.toggle("active");
    this.arrowTarget.classList.toggle("active");
    this.headerTarget.classList.toggle("active");
  }

  selectItem(event) {
    const selectedItem = event.currentTarget;

    this.hiddenSelectTarget.value = selectedItem.dataset.value;

    this._setCurrentValue()
    this._updateHeaderText();
    this._hideList();

    this.itemTargets.forEach(item => item.classList.remove("active"));
    selectedItem.classList.add("active");

    this.element.submit(); // form submission
  }

  _setCurrentValue() {
    this.currentValue = this.hiddenSelectTarget.value;
  }

  _updateHeaderText() {
    const selectedItem = this.itemTargets.find(item => item.dataset.value === this.currentValue);
    if (selectedItem) { this.textTarget.textContent = selectedItem.textContent; }
  }

  _hideList() {
    this.listTarget.classList.remove("active");
    this.arrowTarget.classList.remove("active");
    this.headerTarget.classList.remove("active");
  }
}
