import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

// Connects to data-controller='admin-tiles-product-toggler'
export default class extends Controller {
  toggleShowOnCollectionColors(event) {
    const productId = event.params.index
    Rails.ajax({
      type: "patch",
      url: `/admin/tiles/products/${productId}/toggle_show_on_collection_colors`,
    });
  }
}
