import { Controller } from "@hotwired/stimulus"

import Swiper from 'swiper';
import { Pagination, Autoplay, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';

// Connects to data-controller="swiper-collection-product-slider"
export default class extends Controller {
  connect() {
    var collectionProductSwiper = new Swiper(this.element, {
      modules: [ Navigation ],
      speed: 1500,
      slidesPerView: 1,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}
