import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='compare'
export default class extends Controller {
  toggle(event) {
    event.target.classList.toggle('active');
  }

  toggleShow() {
    this.element.classList.toggle('pressed');
  }
}
