import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

// Connects to data-controller='admin-collection-filter-association'
export default class extends Controller {
  showModal(event) {
    const formParams = {
      filter_collection_type: event.params.filterCollectionType,
      filter_type: event.params.filterType,
      filter_select_id: event.params.filterSelectId,
      filter_checkbox_model: event.params.filterCheckboxModel,
      filter_checkbox_field: event.params.filterCheckboxField,
      filter_checkbox_association: event.params.filterCheckboxAssociation,
    };

    Rails.ajax({
      type: 'GET',
      url: `/admin/filters/new_value_modal`,
      data: new URLSearchParams(formParams).toString(),
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => Turbo.renderStreamMessage(data),
      error: (error) => console.error(error)
    });
  }

  createFormSubmit(event) {
    event.preventDefault();

    const form = this.element;
    const formData = new FormData(form);

    const normalizeValue = (value) => (value === 'undefined' || value === undefined ? null : value);

    const selectId = normalizeValue(formData.get('filter_value[select_id]'));
    const checkboxModel = normalizeValue(formData.get('filter_value[checkbox_model]'));
    const checkboxField = normalizeValue(formData.get('filter_value[checkbox_field]'));
    const checkboxAssociation = normalizeValue(formData.get('filter_value[checkbox_association]'));

    Rails.ajax({
      type: 'POST',
      url: form.action,
      data: formData,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        Turbo.renderStreamMessage(data);
        this._appendLastFilterValue(selectId, checkboxModel, checkboxField, checkboxAssociation);
      },
      error: (error) => console.error(error)
    });
  }

  _appendLastFilterValue(selectId, checkboxModel, checkboxField, checkboxAssociation) {
    Rails.ajax({
      type: 'GET',
      url: `/admin/filters/get_last_filter_value`,
      accept: 'json',
      success: (data) => {
        if (checkboxModel && checkboxField && checkboxAssociation) {
          const checkboxesDiv = document.getElementsByClassName(`${checkboxModel}_${checkboxAssociation}`)[0]
          const id = `${checkboxModel}_${checkboxField}_${data.id}`
          const name = `${checkboxModel}[${checkboxField}][]`

          const span = document.createElement('span');
          span.classList.add('checkbox');
          span.classList.add('small-checkbox');
          span.innerHTML = `<label for="${id}">
                              <input class="check_boxes optional" type="checkbox" value="${data.id}" name="${name}" id="${id}">${data.name}
                            </label>`;

          checkboxesDiv.append(span);
        } else {
          const option = new Option(data.name, data.id, false, true);
          const selectField = document.getElementById(selectId)
          selectField.append(option);
          selectField.dispatchEvent(new Event('change'));
        }
      },
      error: (error) => console.error(error)
    });
  }
}
