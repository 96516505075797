import { Turbo } from "@hotwired/turbo-rails"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

import './controllers'

require('./packs/theme')
require('fslightbox');

require('./packs/hcaptcha');
require('./packs/header')
require('./packs/anchors')
require('./packs/slider')
require('./packs/download_btn');
require('./packs/products');
require('./packs/compare');
require('./packs/copy');
