import Swiper from 'swiper';
import { Pagination, Autoplay, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';

import $ from 'jquery';


$(document).on('turbo:load', function () {
  var galleryProductSlider = new Swiper('.gallery-slider', {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 16,
  });

  var showProductSlider = new Swiper('.product-show-slider', {
    modules: [Thumbs, Navigation],
    slidesPerView: 1,
    spaceBetween: 4,
    thumbs: {
      swiper: galleryProductSlider,
    },
    navigation: {
      nextEl: '.show-swiper-button.next',
      prevEl: '.show-swiper-button.prev',
    },
  });

  var showInteriorsSlider = new Swiper('.show-interiors-slider', {
    modules: [Navigation],
    slidesPerView: "auto",
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  document.querySelectorAll('.show-interiors-slider .swiper-slide').forEach((slide, index) => {
    const countBox = slide.querySelector('.count-box');
    if (countBox) {
      countBox.textContent = index + 1;
    }
  });

  var myCompareSwiper = new Swiper('.compare-swiper', {
    modules: [Navigation],
    slidesPerView: "auto",
    speed: 500,
    navigation: {
      nextEl: '.swiper-compare-button-next',
      prevEl: '.swiper-compare-button-prev',
    },
  });
});



document.addEventListener('turbo:load', () => {
  const bigSwiper = document.querySelector('.big-swiper');
  const bigSwiperPages = document.querySelector('.big-swiper-pages');

  if (!bigSwiper || !bigSwiperPages) return;

  const bigSwiperSlides = bigSwiper.querySelectorAll('.swiper-slide');
  const pagesWrapper = bigSwiperPages.querySelector('.swiper-wrapper');

  pagesWrapper.innerHTML = '';

  bigSwiperSlides.forEach((_, index) => {
    const slideNumber = index + 1;

    const pageSlide = document.createElement('div');
    pageSlide.classList.add('swiper-slide');

    const span = document.createElement('span');
    span.textContent = slideNumber;
    pageSlide.appendChild(span);

    pagesWrapper.appendChild(pageSlide);
  });

  const paginationSwiper = new Swiper('.big-swiper-pages', {
    loop: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    slideToClickedSlide: true,
  });

  const bigSwiperInstance = new Swiper('.big-swiper', {
    modules: [Navigation, Pagination, Thumbs],
    loop: true,
    speed: 1500,
    slidesPerView: 'auto',
    centeredSlides: true,
    navigation: {
      nextEl: '.big-swiper-button-next',
      prevEl: '.big-swiper-button-prev',
    },
    thumbs: {
      swiper: paginationSwiper,
    },
  });

  bigSwiperInstance.on('slideChange', () => {
    const activeIndex = bigSwiperInstance.realIndex;
    paginationSwiper.slideToLoop(activeIndex);
  });

  paginationSwiper.on('click', (swiper) => {
    const clickedIndex = swiper.clickedIndex;
    if (clickedIndex !== undefined) {
      paginationSwiper.slideToLoop(clickedIndex);
    }
  });
});

