import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tiles-collection-show-filter"
export default class extends Controller {
  static targets = ["clearFiltersButton"]

  connect() {
    const radiobuttons = this.element.querySelectorAll('input[type="radio"]')

    radiobuttons.forEach(radiobutton => {
      radiobutton.addEventListener('change', this.handleRadiobuttonChange.bind(this))
    })
  }

  handleRadiobuttonChange(event) {
    const form = this.element;

    let formData = new FormData(form);
    formData.delete("authenticity_token");
    let formDataUrl = new URLSearchParams(formData).toString();
    const urlWithoutParams = window.location.origin + window.location.pathname;
    const newFilteredUrl = `${urlWithoutParams}?${formDataUrl}`
    window.history.replaceState({}, document.title, newFilteredUrl)

    this.clearFiltersButtonTarget.classList.remove('hidden')

    form.requestSubmit();
  }

  clearFilters(event) {
    const radiobuttons = this.element.querySelectorAll('input[type="radio"]')
    radiobuttons.forEach(radiobutton => { radiobutton.checked = false; })

    this.clearFiltersButtonTarget.classList.add('hidden')

    window.history.replaceState({}, document.title, window.location.origin + window.location.pathname)
  }
}
