import { Controller } from '@hotwired/stimulus'

// Connects to data-controller='document-uploader'
export default class extends Controller {
  static targets = ['label', 'download', 'window', 'destroy'];

  upload(event) {
    if (event.target.files.length < 0) return;

    this.downloadTarget.href = URL.createObjectURL(event.target.files[0]);
    this.labelTarget.innerHTML = event.target.files[0].name;
    this.windowTarget.classList.add('filled');

    this.destroyTarget.value = '0';
  }

  remove(event) {
    if (!confirm('Are you sure you want to delete this document?')) return;

    this.downloadTarget.href = '';
    this.labelTarget.innerHTML = event.params.label;
    this.windowTarget.classList.remove('filled');

    this.destroyTarget.value = '1';
  }
}
