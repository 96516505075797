import $ from 'jquery';


$(document).on('turbo:load', function () {
  const mediaQuery = window.matchMedia('(min-width: 601px)');

  if (!mediaQuery.matches) return;

  let $characteristicsOverlay = $('.all-characteristics');
  let $characteristicsWrapper = $('.characteristics-wrapper');
  let $closeCharacteristicsBtn = $('.close-characteristics');
  let $openCharacteristicsBtns = $('.open-all-characteristics');

  if ($characteristicsOverlay.length) {
    function openCharacteristics() {
      $characteristicsOverlay.css('visibility', 'visible');
      $('body').css('overflow', 'hidden');
    }

    function closeCharacteristics() {
      $characteristicsOverlay.css('visibility', 'hidden');
      $('body').css('overflow', '');
    }

    $closeCharacteristicsBtn.on('click', closeCharacteristics);

    $characteristicsOverlay.on('click', function (event) {
      if (!$(event.target).closest($characteristicsWrapper).length) {
        closeCharacteristics();
      }
    });

    $openCharacteristicsBtns.each(function () {
      $(this).on('click', openCharacteristics);
    });
  }
});



$(document).on('turbo:load', function () {
  const $openAllButton = $('.normal-text.regular.open-all-characteristics');
  const $characteristicsWrapper = $('.over-characteristics-wrapper');

  $openAllButton.on('click', function () {
    if ($(window).width() <= 600) {
      $characteristicsWrapper.toggleClass('mobile-active');

      if ($characteristicsWrapper.hasClass('mobile-active')) {
        $openAllButton.text('Приховати');
      } else {
        $openAllButton.text('Всі характеристики');
      }
    }
  });
});

