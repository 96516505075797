import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="tile-product-show"
export default class extends Controller {
  static targets = ["colorName"]

  selectColor(event) {
    let product_slug = event.target.dataset.productSlug;
    let product_collection_slug = event.target.dataset.productCollectionSlug;
    let product_color_id = event.target.dataset.productColorId;
    let product_color_name = event.target.dataset.productColorName;

    this.colorNameTarget.innerHTML = product_color_name;

    Rails.ajax({
      type: 'POST',
      url: `/tiles/${product_collection_slug}/products/${product_slug}/select_color`,
      data: `product_color_id=${product_color_id}`,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => { Turbo.renderStreamMessage(data); },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
