import { Controller } from "@hotwired/stimulus"

import Swiper from 'swiper';
import { Pagination, Autoplay, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';

// Connects to data-controller="swiper-collection-additional-slider"
export default class extends Controller {
  connect() {
    var collectionAdditionalSwiper = new Swiper(this.element, {
      modules: [Navigation],
      loop: false,
      speed: 800,
      slidesPerView: 3,
      spaceBetween: 4,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1025: {
          slidesPerView: 4,
        },
      },
    });
  }
}
