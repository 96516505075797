import { Controller } from "@hotwired/stimulus";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// Connects to data-controller="fancybox"
export default class extends Controller {
  connect() {
    this.initializeFancybox();
  }

  initializeFancybox() {
    Fancybox.bind("[data-fancybox]", {
      Image: {
        zoom: true,
        Panzoom: {
          maxScale: 5,
          minScale: 2,
          zoom: true,
        }
      },
      clickContent: (current, event) => {
        if (current.type === "image") {
          const instance = Fancybox.getInstance();
          const panzoom = instance.Panzoom;

          if (panzoom) {
            panzoom.zoomIn();
          }
          return false;
        }
      },
    });
  }
}
