import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="plumbing-product-show"
export default class extends Controller {
  static targets = ["colorName"]

  selectColor(event) {
    let product_color_name = event.target.dataset.productColorName;

    this.colorNameTarget.innerHTML = product_color_name;
  }
}
