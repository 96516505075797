import { Controller } from "@hotwired/stimulus"

Number.prototype.format = function(n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

// Connects to data-controller="wishlist-specification"
export default class extends Controller {
  static targets = [ 'price', 'fullPrice', 'quantityType', 'quantity' ]

  updateTilePrice(event) {
    const price = event.params.price
    const multiplierPrice = event.params.multiplierPrice
    const quantity = this.quantityTarget.value || 1

    const choosedType = this.quantityTypeTargets.find((type) => type.checked === true).value
    const choosedMultiplierPrice = choosedType === 'м/2' ? 1 : multiplierPrice

    const outputPrice = (price * choosedMultiplierPrice).format(2, 3, ' ', ',')
    const outputFullPrice = (price * quantity * choosedMultiplierPrice).format(2, 3, ' ', ',')

    this.priceTarget.textContent = `${outputPrice} грн`
    this.fullPriceTarget.textContent = `${outputFullPrice} грн`
  }

  updatePlumbingPrice(event) {
    const price = event.params.price
    const quantity = this.quantityTarget.value || 1

    const outputFullPrice = (price * quantity).format(2, 3, ' ', ',')

    this.fullPriceTarget.textContent = `${outputFullPrice} грн`
  }
}
