
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller='admin-tile-collections-filter'
export default class extends Controller {
  static targets = ["manufacturer", "brand", "collection"];

  submitForm(event) {
    const collectionType = event.params.collectionType;

    // if the manufacturer is cleared, clear the brand and collection
    if (collectionType === "manufacturer" && this.manufacturerTarget.value === '') {
      this.brandTarget.value = '';
      this.collectionTarget.value = '';
    }

    this.element.requestSubmit();
  }
}
